.shepherd-content{
    font-family: "Helvetica Neue", Inter, ui-sans-serif, "Apple Color Emoji", Helvetica, Arial, sans-serif;
  }
  .shepherd-theme-custom{
    z-index: 98;
    background-color: #f8f4f4;
    padding: 0;
    border-radius: 5px;
    width: 300px;
  }
  .shepherd-header{
    min-height: 40px;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    position: relative;
    background-color: #e8e4e4;
    text-align: center;
  }
  .shepherd-title{
    color: #616161;
    font-size: 12px;
    font-weight:300;
    min-height: 1.5em;
  }
  .shepherd-cancel-icon{
    display: none;
  }
  .shepherd-text{
    color: #616161;
    padding: 5px;
    font-size:  11px;
  }
  
  .shepherd-footer{
    background-color: #f8f4f4;
    width: 300px;
    height: 50px;
    text-align: right;
    border-radius: 0 0 5px 5px;
    padding: 10px;
  }
  .shepherd-button {
    box-sizing: content-box;
    position: relative;
    padding: .5rem .5rem;
    border: 1px solid #616161;
    font-size: 12px;
    color: #424242;
    outline: 0;
    border-radius: .2em;
    margin-left: 2px;
    max-height: 10px;
  }
  .shepherd-button-primary-next{
    color: white;
    background-color: #08c;
  }
  .shepherd-button-primary-next:hover{
    color: white;
    background-color: rgb(42, 161, 220);
  }
  .shepherd-button-primary-back{
    color: white;
    background-color: rgb(125, 131, 134);
  }
  .shepherd-button-primary-back:hover{
    color: white;
    background-color: rgb(108, 112, 114);
  }
  .shepherd-modal-overlay-container.shepherd-modal-is-visible {
    height: 100vh;
    opacity: .5;
    transform: translateZ(0);
    transition: all .3s ease-out,height 0s 0s,opacity .3s 0s
}
.shepherd-modal-overlay-container {
    height: 0;
    left: 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: all .3s ease-out,height 0ms .3s,opacity .3s 0ms;
    width: 100vw;
    z-index: 9997
}
.shepherd-element {
    z-index: 9999;
}