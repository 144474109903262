.principal-home{
    display: flex;
    background-color: white;
    .conteiner{
        font-size: 12px; /* Cambiar el tamaño de la tipografia */
        text-align: center;
        font-weight: bold; /* Fuente en negrita o bold */
        color: #ffffff; /* Color del texto */
        border-radius: 5px; /* Borde del boton */
        letter-spacing: 2px; /* Espacio entre letras */
        background-color: #B7D267; /* Color de fondo */
        padding-top: 10px;
        position: fixed;
        bottom: 20px;
        right: 10px;
        transition: all 300ms ease 0ms;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        z-index: 99;
        width: 100px;
        height: 120px;
        button{
            background-color: #ffffff00;
            p{
                font-size:medium;
                color: #fff;
                font-weight: bolder;
                span{
                    color: #183400;
                    font-weight: bold;
                }
            }
        }
    }
    .conteiner:hover{
        background-color: #B7D267; /* Color de fondo al pasar el cursor */
        box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
        transform: translateY(-7px);
    }
    .help{
        font-size: 12px; /* Cambiar el tamaño de la tipografia */
        text-align: center;
        font-weight: bold; /* Fuente en negrita o bold */
        color: #ffffff; /* Color del texto */
        border-radius: 50px; /* Borde del boton */
        letter-spacing: 2px; /* Espacio entre letras */
        background-color: #9ad35a; /* Color de fondo */
        position: fixed;
        bottom: 500px;
        right: 10px;
        transition: all 300ms ease 0ms;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        z-index: 999;
        width: fit-content;
        height: fit-content;
        button{
            background-color: #ffffff00;
            p{
                font-size:medium;
                color: #fff;
                font-weight: bolder;
                span{
                    color: #183400;
                    font-weight: bold;
                }
            }
        }
    }
    .help:hover{
        background-color: #8bd267; /* Color de fondo al pasar el cursor */
        box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
        transform: translateY(-7px);
    }
    
    .tienda{
        flex: 1;
    }
    .chatbot{
        display: flex;
        //z-index: 1;
        flex: 0 0 250px;
        padding-top: 120px;
        margin-right: 50px;
        background-color: #f8f4f4;
        color: black;
    }
    .chat{
        z-index: 1000;
        position:fixed;
        margin-right: 10px;
        background-color:#ffff;
        width: 300px;
        height: 80vh;
        border-radius: 10px 10px 0 0;
        overflow-y:scroll;
        overflow-x: hidden;
        font-size: 14px;
        font-family: MiFuente;
        scroll-padding-inline-start: 20px;
        .limpiar-chat{
            text-align: right;
            padding-top: 50px;
            padding-right: 20px;
            button{
                padding: 0%;
                color: #9AD000;
                background-color: #fff;
                font-weight: 500;
            }
            button:hover{
                padding: 0%;
                color: #9AD000;
                background-color: #fff;
                font-weight: 800;
            }
        }
        .headder-chat{
            display: flex;
            width: 280px;
            position:fixed;
            flex-direction: row;
            height: 45px;
            background-color: #B7D267;
            padding: 10px;
            border-radius: 10px 10px 0 0;
            p{
                font-size: 22px;
                color: #fff;
                font-weight: bolder;
                span{
                    color: #183400;
                    font-weight: bold;
                }
            }
            img{
                margin-left: 5px;
                margin-right: 10px;
            }
            button{
                margin-top: 0;
                padding-left: 60px;
                padding-bottom: 50px;
                font-size: 21px;
                background-color: #ffffff00;
            }
            button:hover{
                margin-top: 0;
                padding-left: 60px;
                padding-bottom: 50px;
                font-size: 21px;
                background-color: #ffffff00;
                font-weight: bolder;
            }
        }
        .show-msg{
            overflow:auto;
            padding-bottom: 30px;
        }
        .send-msg{
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 8px 2px;
            gap: 8px;
            background-color:#ffffff;
            //position: absolute;
            width: 280px;
            bottom: 0;            
            
            input{
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 8px 10px;
                gap: 8px;
                background: #FFFFFF;
                border: 1px solid #D9D9D9;
                border-radius: 20px 20px 0px 20px;
                height: 39px;
                width: 210px;
            }
            button{
                width: 10px;
                background-color: #ffffff00;
            }
        }
        .chat-box-bot{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;   
            font-weight: 500; 
            gap: 16px;
            margin: 5px;
            padding: 16px;
            background-color: #F4F5F9;
            border-radius: 20px 20px 0px 20px;
        }
        .chatbox-bot{
            gap: 16px;
            margin: 10px;
            padding: 16px;
            background-color: #F4F5F9;
            border-radius: 20px 20px 0px 20px;
        }
        .chat-box-bot-a{
            gap: 16px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;   
            font-weight: 500;
        }
        .chatbox-button{
            margin-left: 60px;
            margin-top: 10px;
            button{
                margin-top: 10px;
                background-color: #183400;
                border-radius: 30px;
                padding: 10px;
                gap: 10px;
            }
            button:hover{
                background-color: #B7D267;
                color: black;
            }
        }
        .chat-box-usr{
            font-weight: 500; 
            margin: 10px;
            padding: 16px;
            background-color: #fff;
            border-radius: 20px 20px 0px 20px;
        }
        
        
        .typing-animation {
            display: flex;
            justify-content: center;
            overflow: hidden;
            white-space: nowrap;
          .dot {
            display: inline-block;
            width: 10px;
            height: 10px;
            background-color: #505351;
            border-radius: 50%;
            margin-right: 4px;
            animation-name: dot;
            animation-iteration-count: infinite;
            animation-timing-function:initial;
          }
          .first{
            animation-duration: 1s;
          }
          .second{
            animation-duration: 1.1s;
          }
          .third{
            animation-duration: 1.2s;
          }
        }         
        
    }
}

.close{
    display: none;
}
@font-face {
    font-family: 'MiFuente';
    src: url('../../fonts/rsc/fonts/MisFuentes/Montserrat-VariableFont_wght.ttf');
}
@keyframes dot {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
}


