.checkout-page {
  min-height: calc(100vh - 120px);
  margin-top: 100px;
  .container {
    max-width: 960px;
    margin: 0 auto;
    padding: 0 32px;
    display: flex;
    .order-details {
      flex-grow: 1;
    }
    .order-summary {
      flex-basis: 300px;
    }
  }
}

.timeline {
  text-align: center;
  li {
    display: inline-block;
    width: 120px;
    cursor: pointer;
    position: relative;
    &:after {
      content: "";
      width: 120px;
      height: 2px;
      background: $gray-eighty;
      display: block;
      position: absolute;
      bottom: 12px;
      z-index: -1;
    }
    &:first-child {
      &:after {
        width: 60px;
        left: 60px;
      }
    }
    &:last-child {
      &:after {
        width: 60px;
      }
    }
    h2 {
      padding: 16px 0;
      text-align: center;
      font-size: 16px;
      color: $gray-light;
    }
    i {
      font-size: 24px;
      color: lighten($gray-eighty, 10);
      border-radius: 15px;
      background: $white;
      border: 2px solid $gray-eighty;
    }
    &.done {
      color: lighten($primary-green, 0);
      i {
        color: lighten($primary-green, 0);
      }
      h2 {
        color: lighten($primary-green, 0);
      }
    }
    &.active {
      color: lighten($primary-green, 15);
      i {
        color: lighten($primary-green, 15);
      }
      h2 {
        color: lighten($primary-green, 15);
      }
    }
  }
}
.order-details {
  padding: 24px;
  background: $white;
  margin: 24px 24px 24px 0;
  border-radius: 6px;
}

.detail-container {
  margin-top: 24px;
  h2 {
    margin: 32px 0;
    text-align: center;
  }
  .auth-message {
    text-align: center;
    padding: 48px 24px;
    border: 1px solid #fff;
    background: $gray-light-bg;
    margin-bottom: 24px;
    border-radius: 8px;
    p {
      margin-bottom: 24px;
      span {
        padding: 4px;
        background: $gray-dark-bg;
        border-radius: 4px;
      }
    }
    button {
      background: transparent;
      color: $primary-green;
      border: 1px solid $primary-green;
      border-radius: 8px;
    }
  }
  .actions {
    display: flex;
    justify-content: space-between;
    button {
      border-radius: 8px;
      height: 48px;
      i {
        font-size: 20px;
        vertical-align: middle;
      }
      &:first-child {
        background-color: transparent;
        color: $primary-green;
      }
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}

.order-summary {
  padding: 24px;
  background: $white;
  margin: 24px 0;
  border-radius: 6px;
  h2 {
    padding: 16px;
    span {
      color: $gray-light;
      font-weight: normal;
    }
  }
  .cart-items {
    width: 300px;
    max-height: 256px;
    overflow-y: auto;
  }
  .total-breakup {
    border-top: 1px solid $gray-dark-bg;
    padding-top: 24px;
    margin-top: 16px;
    li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;
      h2 {
        padding: 16px 0;
      }
    }
  }
}

.banner{
  background-color: #accc64;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  height: 200px;
  border-radius: 10px;
  margin: 20px 60px 60px;
  padding-top: 20px;
  text-align: center;
  line-height: 1.5;
  span{
    color: #045018;
  }
  h1{
    text-align: center;
    color: #ffffff;
    font-weight: bolder;
  }
  p{
    padding-left: 20px;
    padding-right: 20px;
    text-align: justify;
    color: #ffffff;
    font-size: larger;
  }
  button{
    background-color: #ffffff;
    color: #01270b;
    font-size: smaller;
    text-align: center;
    font-weight: bolder;
    border-radius: 10px;
    width:200px;
    height: 30px;
  }
  button:hover{
    background-color: #087c14;
    color: #fdfdfd;
    font-size: smaller;
    text-align: center;
    font-weight: bolder;
    border-radius: 10px;
    width:200px;
    height: 30px;
  }
}
.modal{
  background-color: rgba(0, 0, 0, 0.582);
  width: 100vw;
  height: 200vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  text-align: center;
}
.modal-dialog {
  font-family:MiFuente;
  margin: 40px 80px 20px 80px;
  padding: 0px;
  border-radius: 10px;
  height: 545px;
  box-shadow: 0px 10px 20px rgba(30, 21, 76, 0.534);
  background-color: #ffffff;
}
.headder{
  background-color: #203404;
  height: fit-content;
  padding-top: 0;
  padding-left: 15px;
  padding-bottom: 0;
  color: #ffffff;
  font-size: large;
  border-radius: 9px 9px 0 0;
  button{
    background-color: #ffffff00;
    color: #ffffff;
    font-size: larger;
    width: auto;
  }
  button:hover{
    background-color: #ffffff00;
    color: #ffffff;
    font-size: larger;
    width: auto;
  }
  table{
    width: 100%;
  }
  th{
    text-align: left;
    font-size: medium;
  }
  .th_r{
    text-align: right;
    font-weight: lighter;
    font-size: medium;
  }
}
.categorias{
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  p{
    font-size: medium;
    text-align: left;
    color: #01270b;
  }
  button{
    background-color: #ffffff;
    border: 2px solid #accc64;
    color: #accc64;
    font-size: smaller;
    text-align: center;
    font-weight: bolder;
    border-radius: 10px;
    width:150px;
    margin-top: 10px;
    margin-left: 20px;
    height: 30px;
  }
  .genera-recepie{
    width:fit-content;
  }
  .genera-recepie:hover{
    width:fit-content;
  }
  .genera-recepie:disabled{
    width:fit-content;
  }
  button:hover{
    border: 2px solid #accc64;
    background-color: #accc64;
    color: #ffffff;
    font-size: smaller;
    text-align: center;
    font-weight: bolder;
    border-radius: 10px;
    width:150px;
    margin-left: 20px;
    height: 30px;
  }
  button:disabled{
    border: 2px solid #646262;
    background-color: #ffffff;
    color: #646262;
    font-size: smaller;
    text-align: center;
    font-weight: bolder;
    border-radius: 10px;
    width:150px;
    margin-left: 20px;
    height: 30px;
  }
  .divider {
    border-top: 10px solid #000;
  }
}
.recipies{
  display: inline-block;
  margin: 10px;
  button{
    background-color: #fcfcfc00;
    width: 25%;
    height: fit-content;
    padding: 8px
  }
  button:hover{
    background-color: #fcfcfc00;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    width: 25%;
    height: fit-content;
    padding: 8px;
  }
  p{
    text-align: center;
    color: black;
  }
}
.modal-recipe{
  background-color: rgba(0, 0, 0, 0);
  width: 100vw;
  height: 200vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  text-align: center;
  button{
    width: fit-content;
    height: fit-content;
  }
  button:hover{
    width: fit-content;
    height: fit-content;
  }
}
.modal-dialog-recipe {
  font-family:MiFuente,MiFuente_2;
  margin: 20px 80px 20px 80px;
  padding: 0px;
  border-radius: 10px;
  height: 96vh;
  box-shadow: 0px 10px 20px rgba(30, 21, 76, 0.534);
  background-color: #ffffff;
}
.headder-recipe{
  background-color: #485434;
  height: fit-content;
  padding-top: 0;
  padding-bottom: 0;
  color: #ffffff;
  font-size: large;
  border-radius: 9px 9px 0 0;
  button{
    background-color: #ffffff00;
    color: #ffffff;
    font-size: larger;
    font-weight: lighter;
    width: auto;
  }
  button:hover{
    background-color: #ffffff00;
    color: #ffffff;
    font-size: larger;
    font-weight:bolder;
    width: auto;
  }
  .regresar{
    font-weight: lighter;
    background-color: #00000000;
    color: #ffffff;
    font-size:xx-large;
    width: fit-content;
  }
  .regresar:hover{
    font-weight: bolder;
    background-color: #00000000;
    color: #ffffff;
    font-size:xx-large;
    width: fit-content;
  }
  table{
    width: 100%;
  }
  th{
    text-align: left;
    font-size: medium;
  }
  .th_r{
    text-align: right;
    font-size: medium;
  }
}
.recepiedescription{
    min-height: calc(100vh - 120px);
    .container {
      max-width: 960px;
      margin: 0 auto;
      padding: 0 10px;
      display: flex;
      .ingredients {
        flex-basis: 1000px;
      }
      .missing-ingredients {
        flex-basis: 1000px;
      }
    }
}
.ingredients {
  overflow: auto;
  padding: 5px 10px 10px 20px;
  background: #fffffe;
  margin: 10px 24px 24px 0;
  border-radius: 6px;
  max-height: 70vh;
  h3{
    color: #accc64;
    text-align: left;
    font-size: medium;
  }
  li{
    margin-left: 10px;
    list-style-type:disc;
    text-align: left;
    font-size: smaller;
    line-height: 15px;
  }
  .instructions{
    li{
      margin-left: 0;
      list-style-type:decimal;
      text-align: justify;
      font-size: small;
      line-height: 20px;
    }
  }
}
.missing-ingredients {
  background: #ffffff;
  border-style: solid;
  border-color: #b0c464;
  border-width: 0.5px;
  margin: 10px 0;
  border-radius: 6px;
  height: fit-content;
  h2 {
    padding: 10px 10px 10px 10px;
    color: #045018;
    span {
      color: $gray-light;
      font-weight: normal;
    }
  }
  .headder-missing-ingredients{
    background-color: #b0c464;
    height: 4vh;
    padding-left: 6px;
    color: #ffffff;
    font-size: larger;
    border-radius: 6px 6px 0 0;
    h3{
      padding-top: 3px;
      font-size: 12px;
      color: #ffffff;
    }
  }
  .cart-items {
    width: auto;
    height: calc(100vh - 200px);
    overflow-y: scroll;
    button{
      background-color: #506c3c;
      height: fit-content;
      color: #edf0e4;
      width: fit-content;
      border-radius: 3px;
    }
  }
  .total-breakup {
    border-top: 1px solid $gray-dark-bg;
    padding-top: 24px;
    margin-top: 16px;
    li {
      text-align: left;
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;
      h2 {
        padding: 16px 0;
      }
    }
  }
}
.add-all{
  border-top: solid #accc64;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-top: 10px;
  button{
    background-color: #506c3c;
    height: 20px;
    color: #edf0e4;
    width: 280px;
    border-radius: 3px;
  }
  button:hover{
    background-color: #6b8f50;
    height: 20px;
    color: #edf0e4;
    width: 280px;
    border-radius: 3px;
  }
}
.none{
  display: none;
}
.add-rm-item{
  
  button{
    background-color: #506c3c;
    color: #edf0e4;
    width: fit-content;
    height: fit-content;
    border-radius: 3px;
    padding: 2px 2px 2px 2px;
  }
  .no-bk{
    background-color: #ffffff00;
  }
  button:hover{
    background-color: #6b8f50;
    color: #edf0e4;
    width: fit-content;
    height: fit-content;
    border-radius: 3px;
    padding: 2px 2px 2px 2px;
  }
}
.loading-recepies{
  h3{
    color: #045018;
  }
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  margin-left: 50px;
  border: 16px solid #f3f3f3;
  border-top: 16px solid #045018;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@font-face {
  font-family: 'MiFuente';
  src: url('../../fonts/rsc/fonts/MisFuentes/Montserrat-VariableFont_wght.ttf');
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.help{
  font-size: 12px; /* Cambiar el tamaño de la tipografia */
  text-align: center;
  font-weight: bold; /* Fuente en negrita o bold */
  color: #ffffff; /* Color del texto */
  border-radius: 50px; /* Borde del boton */
  letter-spacing: 2px; /* Espacio entre letras */
  background-color: #9ad35a; /* Color de fondo */
  position: fixed;
  padding: 5px;
  bottom: 500px;
  right: 1px;
  transition: all 300ms ease 0ms;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  z-index: 999;
  width: fit-content;
  height: fit-content;
  button{
      background-color: #ffffff00;
      p{
          font-size:medium;
          color: #fff;
          font-weight: bolder;
          span{
              color: #183400;
              font-weight: bold;
          }
      }
  }
}
.help:hover{
  background-color: #8bd267; /* Color de fondo al pasar el cursor */
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(-7px);
}


